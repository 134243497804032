import React from 'react';

export default function GalleryCollapse(props) {
    const { toggleCollapsed, background_color, accent_color, button_title } =
        props;
    return (
        <div
            className="react-gallery__collapse"
            style={{
                backgroundColor: background_color,
            }}
        >
            <button
                tabIndex={1}
                className={`react-gallery__collapse-button react-gallery__collapse-button--${accent_color}`}
                onClick={() => {
                    toggleCollapsed();
                }}
                style={{
                    backgroundColor: accent_color,
                    color: background_color,
                }}
            >
                {button_title}
                <div
                    className={'square-icon__icon-container square-icon__icon-container--gallery-see-more'}
                >
                    <div
                        className={'square-icon__icon square-icon__icon--gallery-see-more'}
                        style={{
                            backgroundColor: background_color,
                        }}
                    />
                </div>
            </button>
        </div>
    );
}

import $ from 'jquery';
import React from 'react';
import Masonry from 'react-masonry-css';
import LightGallery from 'lightgallery/react';
import lgVideo from 'lightgallery/plugins/video';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { GalleryItem } from 'gallery/components';
import { COLUMN_BREAKPOINTS, LG_LICENSE_KEY } from 'gallery/utils/constants';

const arrowButtonHtml = (d) => {
    return `<div class="react-gallery-masonry__arrow-button"><span class="react-gallery-masonry__arrow-icon react-gallery-masonry__arrow-icon--${d}"/></div>`;
};

export default function GalleryMasonry(props) {
    const { images, background_color, accent_color, layout } = props;

    const onAfterOpen = () => {
        disableBodyScroll($('lg-outer'));
    };

    const onAfterClose = () => {
        clearAllBodyScrollLocks();
        const subHTML = document.querySelector('.react-lg .lg-sub-html');
        subHTML.classList.remove('caption--hide');
    };

    const onAfterAppendSubHtml = () => {
        $('.lg-counter').html((i, html) => {
            return html.replace(' /', ' ');
        });
        const subHTML = document.querySelector('.react-lg .lg-sub-html');
        const toggleButtons = document.querySelectorAll('.react-gallery-masonry__toggle-caption');
        toggleButtons.forEach((elm) => {
            elm.onclick = () => {
                subHTML.classList.toggle('caption--hide');
            };
        });
    };

    return (
        <LightGallery
            speed={500}
            download={false}
            selector=".react-gallery-item"
            plugins={[lgVideo]}
            addClass={`react-lg react-lg--${layout}`}
            id="react-lg"
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
            onAfterAppendSubHtml={onAfterAppendSubHtml}
            showCloseIcon={true}
            nextHtml={arrowButtonHtml('next')}
            prevHtml={arrowButtonHtml('prev')}
            appendCounterTo=".lg-components"
            allowMediaOverlap={true}
            licenseKey={LG_LICENSE_KEY}
            loadYouTubePoster={false}
        >
            <Masonry
                breakpointCols={COLUMN_BREAKPOINTS}
                className="react-gallery-masonry"
                columnClassName="react-gallery-masonry__column"
            >
                {images.map((image, i) => {
                    return (
                        <GalleryItem
                            key={i}
                            image={image}
                            background_color={background_color}
                            accent_color={accent_color}
                            layout={layout}
                        />
                    );
                })}
            </Masonry>
        </LightGallery>
    );
}

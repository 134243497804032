import React, { Component } from 'react';

import { GalleryMasonry, GalleryCollapse } from 'gallery/components';
import { MASONRY } from 'gallery/utils/constants';

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true,
        };
        this.toggleCollapsed = this.toggleCollapsed.bind(this);
    }

    toggleCollapsed(e) {
        this.setState((prevState) => ({
            isCollapsed: !prevState.isCollapsed,
        }));
    }

    componentDidMount() {
        this.setState({
            isCollapsed: this.props.collapsed == 'collapsed',
        });
    }

    render() {
        const {
            title,
            background_color,
            accent_color,
            images,
            layout,
            button_title,
        } = this.props;

        return (
            <div className="react-gallery">
                {title && (
                    <h2
                        className={'react-gallery__title'}
                        style={{
                            backgroundColor: background_color,
                            color: accent_color,
                        }}
                    >
                        {title}
                    </h2>
                )}

                <div
                    className={`react-gallery__gallery ${
                        this.state.isCollapsed
                            ? 'react-gallery__gallery--collapsed'
                            : ''
                    }`}
                    style={{ backgroundColor: background_color }}
                >
                    {this.state.isCollapsed && (
                        <GalleryCollapse
                            toggleCollapsed={this.toggleCollapsed}
                            background_color={background_color}
                            accent_color={accent_color}
                            button_title={button_title}
                        />
                    )}
                    
                    {!title && (
                        <div className="react-gallery__notch react-gallery__notch--top" />
                    )}

                    <GalleryMasonry
                        images={images}
                        background_color={background_color}
                        accent_color={accent_color}
                        layout={layout}
                    />

                    <div
                        className={`react-gallery__notch react-gallery__notch--bottom ${
                            !this.state.isCollapsed
                                ? 'react-gallery__notch--open'
                                : ''
                        }`}
                    />
                </div>
            </div>
        );
    }
}

export default Gallery;

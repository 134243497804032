import React from 'react';

import { MASONRY } from 'gallery/utils/constants';

function ItemCaption(props) {
    const { id, background_color, accent_color, layout, caption } = props;

    return (
        <div
            className={`react-gallery-item__caption-container caption-${id}`}
            style={{
                backgroundColor: background_color,
                borderColor: background_color,
                color: accent_color,
            }}
        >
            {layout == MASONRY && (
                <button
                    className={'react-gallery-masonry__toggle-caption react-gallery-masonry__arrow-button'}
                >
                    <span className="react-gallery-masonry__arrow-icon react-gallery-masonry__arrow-icon--down"></span>
                </button>
            )}
            <div
                className={`react-gallery-item__caption react-gallery-item__caption--${layout}`}
                dangerouslySetInnerHTML={{ __html: caption }}
            />
        </div>
    );
}

function GalleryImage(props) {
    const { media, thumbnail_image, background_color, id, showPlayButton } = props;
    // Note that we do not have the Youtube video included here: This is to prevent the Youtube player
    // from taking over the masonry view. The thumbnail image will display instead.
    if (media) {
        return (
            <video
                className={'react-gallery-item__image'}
                style={{
                    backgroundColor: background_color,
                }}
                autoPlay
                loop
                muted
                playsInline
            >
                <source src={media.target_url} type="video/mp4" />
            </video>
        );
    }
    return (
        <div>
            <img
                className={`react-gallery-item__image image-${id}`}
                src={thumbnail_image}
                style={{
                    backgroundColor: background_color,
                }}/>
            {showPlayButton && <span className="react-gallery-item__play-button"></span>}
        </div>
    );
}

function ItemWithMedia(props) {
    const { caption, thumbnail_image, media, video, id } = props.image;
    const { layout, background_color, accent_color } = props;

    let source = '';
    if (media) source = media.target_url;
    return (
        <a
            data-video={
                media
                    ? `{"source": [{"src":"${source}", "type":"video/mp4"}], "attributes": {"preload": false, "playsinline": true, "controls": false, "loop": true, "playsinline": true, "autoplay": true }}`
                    : ''
            }
            data-sub-html={`.caption-${id}`}
            tabIndex={0}
            className={`react-gallery-item react-gallery-item--${layout} ${
                !caption ? 'react-gallery-item--no-caption' : ''
            } image-${id}`}
            style={{
                backgroundColor: background_color,
                borderColor: background_color,
            }}
        >
            <GalleryImage
                media={media}
                video={video}
                thumbnail_image={thumbnail_image}
                background_color={background_color}
                id={id}
            />
            {caption && (
                <ItemCaption
                    id={id}
                    background_color={background_color}
                    accent_color={accent_color}
                    layout={layout}
                    caption={caption}
                />
            )}
        </a>
    );
}

function ItemWithVideo(props) {
    const { caption, thumbnail_image, media, video, id } = props.image;
    const { layout, background_color, accent_color } = props;

    let source = '';
    if (video) source = video;
    return (
        <a
            data-src={source}
            data-sub-html={`.caption-${id}`}
            tabIndex={0}
            className={`react-gallery-item react-gallery-item--${layout} ${
                !caption ? 'react-gallery-item--no-caption' : ''
            }`}
            style={{
                backgroundColor: background_color,
                borderColor: background_color,
            }}
        >
            <GalleryImage
                media={media}
                video={video}
                thumbnail_image={thumbnail_image}
                background_color={background_color}
                id={id}
                showPlayButton={true}
            />
            {caption && (
                <ItemCaption
                    id={id}
                    background_color={background_color}
                    accent_color={accent_color}
                    layout={layout}
                    caption={caption}
                />
            )}
        </a>
    );
}

function ItemWithImage(props) {
    const { caption, thumbnail_image, full_image, media, video, id } =
        props.image;
    const { layout, background_color, accent_color } = props;

    return (
        <a
            data-src={full_image}
            data-sub-html={`.caption-${id}`}
            tabIndex={0}
            className={`react-gallery-item react-gallery-item--${layout} ${
                !caption ? 'react-gallery-item--no-caption' : ''
            }`}
            style={{
                backgroundColor: background_color,
                borderColor: background_color,
            }}
        >
            <GalleryImage
                media={media}
                video={video}
                thumbnail_image={thumbnail_image}
                background_color={background_color}
                id={id}
            />
            {caption && (
                <ItemCaption
                    id={id}
                    background_color={background_color}
                    accent_color={accent_color}
                    layout={layout}
                    caption={caption}
                />
            )}
        </a>
    );
}

export default function GalleryItem(props) {
    const { media, video } = props.image;

    if (media) {
        return <ItemWithMedia {...props} />;
    }
    if (video) {
        return <ItemWithVideo {...props} />;
    }
    return <ItemWithImage {...props} />;
}

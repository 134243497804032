import React from 'react';
import ReactDOM from 'react-dom';
import Gallery from './Gallery';

// Makes React available as a global variable,
// required to make django-react-templatetags work.
window.React = React;
window.ReactDOM = ReactDOM;

// // Allows component to be accessed by the django-react-templatetags render method.
window.Gallery = Gallery;
